import sendRequest from "../../../app/utils/send-request-helper";

export const getOrganizationList = async (currentPage, limit) => {
  const data = await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/organizations?page=${currentPage}&limit=${limit}`,
    method: "GET",
    isAuth: true,
  });
  return data;
};
