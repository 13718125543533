<template>
  <layout>
    <template #header>
      <app-header>
        <pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @update="updatePage"
        />
      </app-header>
    </template>
    <div class="content">
      <organization-table :organizations="organizations"></organization-table>
    </div>
  </layout>
</template>
<script>
import Header from "../../../app/components/headers/DefaultHeader";
import Pagination from "../../../app/components/Pagination.vue";
import DefaultLayout from "../../../app/components/layouts/DefaultLayout";
import OrganizationTable from "../components/content-table/OrganizationTable.vue";
import { getOrganizationList } from "./organization-list";

export default {
  name: "OrganizationList",
  metaInfo: {
    title: "Organization List",
  },
  components: {
    "app-header": Header,
    layout: DefaultLayout,
    "organization-table": OrganizationTable,
    pagination: Pagination,
  },
  data() {
    return {
      organizations: [],
      currentPage: 1,
      totalPages: 10,
      limit: 10,
    };
  },
  async mounted() {
    await this.getOrgList();
  },
  methods: {
    async updatePage(page) {
      this.currentPage = page;
      await this.getOrgList();
    },
    async getOrgList() {
      try {
        const data = await getOrganizationList(this.currentPage, this.limit);
        this.organizations = [...data.list];
        this.totalPages = data.total_pages;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting organization list"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 15px 40px;
  width: 80%;
  margin: 0 auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
@media (max-width: $breakpoint-tablet-large) {
  .content {
    padding: 10px;
    width: 100%;
  }
}
</style>
