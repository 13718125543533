var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.organizations,
          "expanded-menu-items": _vm.expandedMenuItems
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(ref) {
              var props = ref.props
              return [
                _c("span", { staticClass: "organization-title" }, [
                  _vm._v(" " + _vm._s(props.item.name) + " ")
                ])
              ]
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var props = ref.props
              return [_vm._v(" " + _vm._s(props.item.type) + " ")]
            }
          },
          {
            key: "updated_on",
            fn: function(ref) {
              var props = ref.props
              return [
                _vm._v(
                  " " + _vm._s(_vm.getFormatTime(props.item.updated_on)) + " "
                )
              ]
            }
          },
          {
            key: "created_on",
            fn: function(ref) {
              var props = ref.props
              return [
                _vm._v(
                  " " + _vm._s(_vm.getFormatTime(props.item.created_on)) + " "
                )
              ]
            }
          },
          {
            key: "expanded-row-content",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("org-detail", { attrs: { org: item, isMember: true } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }