<template>
  <div>
    <data-table
      :headers="headers"
      :items="organizations"
      :expanded-menu-items="expandedMenuItems"
    >
      <template v-slot:name="{ props }">
        <span class="organization-title">
          {{ props.item.name }}
        </span>
      </template>
      <template v-slot:type="{ props }">
        {{ props.item.type }}
      </template>
      <template v-slot:updated_on="{ props }">
        {{ getFormatTime(props.item.updated_on) }}
      </template>
      <template v-slot:created_on="{ props }">
        {{ getFormatTime(props.item.created_on) }}
      </template>

      <template v-slot:expanded-row-content="{ item }">
        <org-detail :org="item" :isMember="true" />
      </template>
    </data-table>
  </div>
</template>
<script>
import { getFriendlyFormatTime } from "./organization-table";
import DataTable from "../../../../app/components/tables/DataTable.vue";
import OrgDetail from "../../../../organizations/components/OrgDetailCard.vue";

export default {
  components: {
    "data-table": DataTable,
    "org-detail": OrgDetail,
  },
  props: {
    organizations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", align: "left", value: "name" },
        { text: "Type", align: "left", value: "type" },
        { text: "Last updated", align: "left", value: "updated_on" },
        { text: "Created", align: "left", value: "created_on" },
      ];
    },
    expandedMenuItems() {
      return [
        {
          label: "Edit organization",
          ariaLabel: "edit-org",
          icon: "mdi-pencil",
          action: this.editOrg,
        },
        {
          label: "View profile",
          ariaLabel: "view-org",
          icon: "mdi-account-details",
          action: this.visitOrgProfile,
        },
      ];
    },
  },
  methods: {
    getFormatTime(createdOn) {
      return getFriendlyFormatTime(createdOn);
    },
    editOrg(org) {
      this.$router.push(`/organizations/edit/${org.id}`);
    },
    visitOrgProfile(org) {
      this.$router.push(`/organizations/${org.id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.organization-title {
  font-weight: 600;
  color: $inqliNavy;
}
</style>
